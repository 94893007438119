@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  outline: none;
}

html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  background-color: #000;
  color: #000;
  font-family: 'Electrolize', sans-serif;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  overflow: auto;
}

html, body {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar:vertical {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid;
  border-image-source: var(--ggButtonLinearGradientVertical);
  border-image-slice: 1;
  background: var(--ggBackground);
}

main {
  display: block
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0
}

textarea {
  resize: vertical
}

a {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
}

*[hidden] {
  display: none!important
}

h1,  h2, h3, h4, h5, h6, p {
  padding: 0
}

h1 {
  font-size: 56px;
  line-height: 1.35;
  letter-spacing: -.02em;
  margin: 24px 0
}

h1, h2 {
  font-weight: 400
}

h2 {
  font-size: 45px;
  line-height: 48px
}

h2, h3 {
  margin: 24px 0
}

h3 {
  font-size: 34px;
  line-height: 40px
}

h3, h4 {
  font-weight: 400
}

h4 {
  font-size: 24px;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin: 24px 0 16px
}

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .02em
}

h5, h6 {
  margin: 24px 0 16px
}

h6 {
  font-size: 16px;
  letter-spacing: .04em
}

h6, p {
  font-weight: 400;
  line-height: 24px
}

p {
  font-size: 14px;
  letter-spacing: 0;
  margin: 0 0 16px
}

a {
  font-weight: 500
}

blockquote {
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: .08em
}

blockquote:before {
  position: absolute;
  left: -.5em;
  content: '“'
}

blockquote:after {
  content: '”';
  margin-left: -.05em
}

mark {
  background-color: #f4ff81
}

dt {
  font-weight: 700
}

address {
  font-size: 12px;
  line-height: 1;
  font-style: normal
}

address, ul, ol {
  font-weight: 400;
  letter-spacing: 0
}

ul, ol {
  font-size: 14px;
  line-height: 24px
}

iframe {
  display: block;
  width: 100%;
  border: none
}

.white-space--nowrap {
  white-space: nowrap;
}
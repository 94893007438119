/**
 * @license
 * MyFonts Webfont Build ID 3825928, 2019-10-17T17:25:38-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: EurostileLTStd by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/eurostile/eurostile-63893/
 * Copyright: Copyright &#x00A9; 1987, 1992, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved.&#x00A9; 1987, 1992, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 * Licensed pageviews: 250,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3825928
 * 
 * © 2019 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/3a6108");*/
  
@font-face {font-family: 'EurostileLTStd';src: url('https://bucket.gameglass.gg/fonts/3A6108_0_0.eot');src: url('https://bucket.gameglass.gg/fonts/3A6108_0_0.eot?#iefix') format('embedded-opentype'),url('https://bucket.gameglass.gg/fonts/3A6108_0_0.woff2') format('woff2'),url('https://bucket.gameglass.gg/fonts/3A6108_0_0.woff') format('woff'),url('https://bucket.gameglass.gg/fonts/3A6108_0_0.ttf') format('truetype');}
 
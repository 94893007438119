:root {
  --vw: 100vw;
  --vh: 100vh;

  --ggMainColor: #49E69B;
  --ggMainColorRGB: 73, 230, 155;
  --ggSecondaryColor: #49bebc;
  --ggSecondaryColorRGB: 73, 190, 188;
  --ggInactiveColor: #9BA3B0;
  --ggInactiveColorRGB: 155, 163, 176;
  --ggErrorColor: #FF5947;
  --ggErrorColorRGB: 255, 89, 71;

  --ggBackground: #101219;
  --ggBackgroundRGB: 16, 18, 25;
  --ggLightBackground: #222632;
  --ggLightBackgroundRGB: 34, 38, 50;
  
  --ggButtonLinearGradient: linear-gradient(90deg, var(--ggMainColor) 0%, var(--ggSecondaryColor) 100%);
  --ggButtonLinearGradientReversed: linear-gradient(90deg, var(--ggSecondaryColor) 0%, var(--ggMainColor) 100%);
  --ggButtonLinearGradientVertical: linear-gradient(180deg, var(--ggMainColor) 0%, var(--ggSecondaryColor) 100%);
  --ggButtonLinearGradientReversedVertical: linear-gradient(0deg, var(--ggMainColor) 0%, var(--ggSecondaryColor) 100%);
}